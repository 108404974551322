import {
  type LoaderFunctionArgs,
  redirect,
  type MetaFunction,
} from '@remix-run/node'
import { Link } from '@remix-run/react'
import { Icon } from '#app/components/ui/icon.js'
import { getUserId } from '#app/utils/auth.server.js'
import { APP_NAME, ROLE_ADMIN } from '#app/utils/constants.js'
import { prisma } from '#app/utils/db.server.js'
import { defaultRedirectTo } from '#app/utils/user.js'

export const meta: MetaFunction = () => [
  { title: APP_NAME },
  {
    name: 'description',
    content: `Proven Valor assigns a permanent QR code to each veteran, enabling portable proof of military service. Through our app, or your membership card, Proven Valor provides direct access to your DD214 or its equivalent—the universally accepted proof of service card.`,
  },
]

export async function loader({ request }: LoaderFunctionArgs) {
  const userId = await getUserId(request)

  if (!userId) {
    return null
  }

  const user = await prisma.user.findUnique({
    where: {
      id: userId,
    },
    select: {
      roles: {
        select: {
          name: true,
        },
      },
    },
  })

  const isAdmin = user?.roles.some(role => role.name === ROLE_ADMIN)
  return redirect(defaultRedirectTo(isAdmin))
}

export default function Index() {
  // * Dev note: We have lots of extra padding-top on larger screen sizes so that the anchors will scroll to the correct position
  return (
    <>
      <ProofThatCounts />
      <main>
        <WhatYouGet />
        <Pricing />
      </main>
    </>
  )
}

function ProofThatCounts() {
  return (
    <div className="bg-hero-pattern bg-cover px-4 lg:px-24">
      <div className="bg-transparent pt-16 pb-20 md:pt-12 lg:overflow-hidden lg:pb-14 lg:pt-8">
        <div className="mx-auto max-w-screen-xl">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="mx-auto max-w-md text-center sm:max-w-full lg:flex lg:items-center lg:px-0 lg:text-left">
              <div className="lg:py-10">
                <h1 className="mt-4 text-5xl font-extrabold text-[#283741] sm:mt-4 sm:text-7xl lg:mt-6 xl:text-8xl">
                  <span className="block">Proof that Counts</span>
                </h1>
                <p className="pt-10 text-left text-base leading-relaxed text-[#040819] sm:text-xl lg:text-lg xl:text-xl">
                  Become a member today and never miss out on your entitled veteran benefits again! Your personalized CommandCard™ by Proven Valor provides immediate <span className="font-bold	italic">verified proof</span> of your military service anytime, anywhere. Unlock instant access to important documents like your DD214 or NGB22 stored in your DigitalDuffle™ - a cloud based portal allowing you to show, send and share documents instantly. Plus gain access to exclusive offers curated for you based on your service history and preferences.
                </p>
                <div className="mt-10 sm:mt-12">
                  <div className="justify-center sm:flex lg:justify-start">
                    <div className="mt-4 sm:mt-0">
                      <Link
                        to="/signup"
                        aria-label="Get your membership card"
                        className="block w-full rounded-md bg-crimson px-4 py-2 font-medium uppercase text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 pt-10 lg:m-0">
              <div className="h-full mx-auto max-w-md sm:max-w-2xl lg:max-w-none sm:px-6 lg:px-0">
                <img
                  src="/img/marketing-index-hero.jpeg"
                  alt="Membership card"
                  className="h-full w-full rounded-lg object-cover object-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function WhatYouGet() {
  return (
    <div
      className="relative scroll-mt-16 bg-gray-50 px-4 pt-16 sm:scroll-mt-36 lg:px-24"
      id="what-you-get"
    >
      <div className="mx-auto max-w-md pb-32 text-center sm:max-w-3xl lg:max-w-screen-xl">
        <div>
          <h2 className="text-2xl font-bold tracking-tight text-[#283741] sm:text-4xl">
            What You Get
          </h2>
        </div>
        <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2">
          <div className="flex flex-col items-center align-middle">
            <div className="flex h-24 w-24 items-center justify-center rounded-full bg-[#f5f6fa] align-middle">
              <img src="/img/offer-1-icon.svg" alt="Offer 1" />
            </div>
            <h3 className="mt-4 text-lg font-bold text-[#283741]">
              CommandCard™
            </h3>
            <p className="mt-4 text-left leading-relaxed text-[#040819]">
              Never miss another veteran discount. After joining, you’ll receive the personalized heavy copper, laser-engraved CommandCard™ by Proven Valor that will turn heads with its sleek design and substantial weight. Each card includes your name, rank, branch, exit year, and your unique QR code that links to your official DD214 or NGB22. You will also receive a digital card for your Apple or Google Wallet.
            </p>
          </div>
          <div className="flex flex-col items-center align-middle">
            <div className="flex h-24 w-24 items-center justify-center rounded-full bg-[#f5f6fa] align-middle">
              <img src="/img/offer-2-icon.svg" alt="Offer 2" />
            </div>
            <h3 className="mt-4 text-lg font-bold text-[#283741]">
              DigitalDuffle™
            </h3>
            <p className="mt-4 text-left leading-relaxed text-[#040819]">
              Access your discharge documents anytime, anywhere. We locate, retrieve, verify, digitize and safely secure your documents, military personnel files and medical records. We then create your DigitalDuffle™, a permanent online storage system for your documents with secure and easy sharing - download, email, fax and print anytime. You have the option to assign multiple battle buddies to your DigitalDuffle™ - granting any one access as you choose, when their support is needed.
            </p>
          </div>
          <div className="flex flex-col items-center align-middle">
            <div className="flex h-24 w-24 items-center justify-center rounded-full bg-[#f5f6fa] align-middle">
              <img src="/img/offer-3-icon.svg" alt="Offer 3" />
            </div>
            <h3 className="mt-4 text-lg font-bold text-[#283741]">
              Exclusive Offers & Value
            </h3>
            <p className="mt-4 text-left leading-relaxed text-[#040819]">
              Unlock the full potential of your veteran benefits. You will have access to exclusive, unadvertised discounts curated for veterans, all in one easy-to-use platform. The more deals you snag, the better we can tailor awesome suggestions for you. With your permission, we will screen your records to determine eligibility for government or private party compensation based on your personal service history. Plus, you get complimentary assistance navigating VA and other government benefits, ensuring you get the most out of what you’ve earned.
            </p>
          </div>
          <div className="flex flex-col items-center align-middle">
            <div className="flex h-24 w-24 items-center justify-center rounded-full bg-[#f5f6fa] align-middle">
              <img src="/img/offer-4-icon.svg" alt="Offer 4" />
            </div>
            <h3 className="mt-4 text-lg font-bold text-[#283741]">
              Proof Made Easy
            </h3>
            <p className="mt-4 text-left leading-relaxed text-[#040819]">
              Your dedicated access will provide you with the portable proof you need to verify your status quickly with retailers, healthcare providers or government entities. There are thousands of locations with discounts and offerings available to you but require proof of service - <a href="https://www.provenvalor.com/signup" className="font-bold italic text-[#334876]"><span >that's Proven Valor</span></a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}


function Pricing() {
  return (
    <div
      id="pricing"
      className="relative scroll-mt-12 py-16 sm:scroll-mt-32 sm:pb-16 lg:px-24 bg-[#334876]"
    >
      <div className="mx-auto max-w-md text-center sm:max-w-3xl lg:max-w-screen-xl">
        <h2 className="text-base font-semibold uppercase tracking-wider text-gray-200">
          Pricing
        </h2>
        <p className="mt-4 text-2xl font-bold tracking-tight text-white sm:text-4xl">
          Become a Member Today
        </p>
        <div className="flex justify-center">
          <div className="mt-8 max-w-2xl">
            <div className="flex max-w-md flex-col justify-center rounded-md border bg-white p-8 shadow-md">
              <div className="text-[#283741]">
                <span>$</span> <span className="text-5xl font-bold">8.95</span> <span className="text-2xl line-through">14.95</span>
                <span> / month</span>
                <p><span className="font-bold">DD214 Direct</span> Offer for Monthly Membership </p>
                <p>and WAIVED document retrieval fee, a <span className="line-through">$119</span> savings</p>
              </div>
              <div className="mt-4 flex justify-around">
                <ul className="mt-4 text-xl leading-loose text-[#040819]">
                  <li className="flex items-start gap-2 text-left">
                    <Icon name="success" color="#0fc885" size="lg" className="flex-none self-start mt-2" />
                    CommandCard™ proof of service
                  </li>
                  <li className="flex items-start gap-2 text-left">
                    <Icon name="success" color="#0fc885" size="lg" className="flex-none self-start mt-2" />
                    Instant document transfer from DD214
                  </li>
                  <li className="flex items-start gap-2 text-left">
                    <Icon name="success" color="#0fc885" size="lg" className="flex-none self-start mt-2" />
                    DigitalDuffle™ online storage
                  </li>
                  <li className="flex items-start gap-2 text-left">
                    <Icon name="success" color="#0fc885" size="lg" className="flex-none self-start mt-2" />
                    Exclusive Proven Valor veteran community offers
                  </li>
                  <li className="flex items-start gap-2 text-left">
                    <Icon name="success" color="#0fc885" size="lg" className="flex-none self-start mt-2" />
                    Curated nationwide discounts
                  </li>
                </ul>
              </div>
              <div>
                <div className="mt-4">
                  <GetStartedButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function GetStartedButton() {
  return (
    <Link
      to="/signup"
      aria-label="Sign up for $8/month"
      className="block w-full rounded-md bg-crimson px-4 py-2 font-medium uppercase text-white shadow focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900 text-center"
    >
      Get Started
    </Link>
  )
}
